/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 10:16:41
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-09-19 14:40:38
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signIn',
    name:"signIn",
    component: resolve => require(['../pages/SignIn/signIn'], resolve),
  },  
  {
    path: '/panoramaView',
    name:"panoramaView",
    component: resolve => require(['../pages/PanoramaManagement3D/panoramaView'], resolve),
  },  
  //store
  {
    path: '/',
    name:"store",
    component: resolve => require(['../pages/HomePage/store'], resolve),
    children:[
      {
        path: '/',
        name:'summarizationData',
        component: resolve => require(['../pages/DataReport/summarizationData'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/summarizationData',
        name:'summarizationData',
        component: resolve => require(['../pages/DataReport/summarizationData'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/clientList',
        name:'clientList',
        component: resolve => require(['../pages/CustomerManagement/clientList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/scenariosStyle',
        name:'scenariosStyle',
        component: resolve => require(['../pages/PanoramicManagement/scenariosStyle'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/scenariosList',
        name:'scenariosList',
        component: resolve => require(['../pages/PanoramicManagement/scenariosList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/housingTypeManagement',
        name:'housingTypeManagement',
        component: resolve => require(['../pages/PanoramicManagement/housingTypeManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/familyManagement',
        name:'familyManagement',
        component: resolve => require(['../pages/PanoramicManagement/familyManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/designAssistant',
        name:'designAssistant',
        component: resolve => require(['../pages/PanoramicManagement/designAssistant'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/mySaveList',
        name:'mySaveList',
        component: resolve => require(['../pages/PanoramaManagement3D/mySaveList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/panoramicMaterial',
        name:'panoramicMaterial',
        component: resolve => require(['../pages/PanoramaManagement3D/panoramicMaterial'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/uploadMaterial',
        name:'uploadMaterial',
        component: resolve => require(['../pages/PanoramaManagement3D/uploadMaterial'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/productShop',
        name:'productShop',
        component: resolve => require(['../pages/ProductManagement/productShop'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/sizeManagement',
        name:'sizeManagement',
        component: resolve => require(['../pages/ProductManagement/sizeManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/productList',
        name:'productList',
        component: resolve => require(['../pages/ProductManagement/productList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/distributeList',
        name:'distributeList',
        component: resolve => require(['../pages/SharedCircleManagement/distributeList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/channelList',
        name:'channelList',
        component: resolve => require(['../pages/CustomerManagement/channelList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/tutorialsList',
        name:'tutorialsList',
        component: resolve => require(['../pages/VideoTutorial/tutorialsList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/operationList',
        name:'operationList',
        component: resolve => require(['../pages/OperatingHistory/operationList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/visitorList',
        name:'visitorList',
        component: resolve => require(['../pages/VisitManagement/visitorList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/visitor',
        name:'visitor',
        component: resolve => require(['../pages/VisitManagement/visitor'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/lighthouseList',
        name:'lighthouseList',
        component: resolve => require(['../pages/LighthouseManagement/lighthouseList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/lighthouseDaList',
        name:'lighthouseDaList',
        component: resolve => require(['../pages/LighthouseManagement/lighthouseDaList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/lighthouseSCList',
        name:'lighthouseSCList',
        component: resolve => require(['../pages/LighthouseManagement/lighthouseSCList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/PanoramaManagement3D/PanoramaMaterialKind',
        name: '素材分类',
        component: resolve => require(['../pages/PanoramaManagement3D/PanoramaMaterialKind'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/PanoramaManagement3D/changedPanoramaMaterial',
        name: '修改素材',
        component: resolve => require(['../pages/PanoramaManagement3D/changedPanoramaMaterial'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }
    ]    
  },
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/signIn')) {
      window.localStorage.removeItem('token')
      next()
  } else {
      let user = window.localStorage.getItem('token');
      if (!user && to.path !== '/signIn') {
          next({
              path: '/signIn'
          })
      } else {
          next()
      }
  }
});

export default router
