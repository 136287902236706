/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:04:28
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-09-25 12:03:06
 */
var storeMenu = [
    {
        icon:"el-icon-s-home",
        index:"DataReport",
        sbuMenu:true,
        title:"控制台",
        type:0,
        subs:[
            {
                icon:"",
                index:"summarizationData",
                sbuMenu:true,
                subs:null,
                title:"数据汇总",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-s-custom",
        index:"CustomerManagement",
        sbuMenu:true,
        title:"客户管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"clientList",
                sbuMenu:true,
                subs:null,
                title:"普通客户",
                type:0,
            },
            {
                icon:"",
                index:"channelList",
                sbuMenu:true,
                subs:null,
                title:"渠道客户",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-s-help",
        index:"SharedCircleManagement",
        sbuMenu:true,
        title:"共享圈管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"distributeList",
                sbuMenu:true,
                subs:null,
                title:"我的发布列表",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-s-shop",
        index:"ProductManagement",
        sbuMenu:true,
        title:"产品管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"productList",
                sbuMenu:true,
                subs:null,
                title:"产品列表",
                type:0,
            },
            {
                icon:"",
                index:"productShop",
                sbuMenu:true,
                subs:null,
                title:"门店产品",
                type:0,
            },
            {
                icon:"",
                index:"sizeManagement",
                sbuMenu:true,
                subs:null,
                title:"规格管理",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-camera",
        index:"PanoramaManagement3D",
        sbuMenu:true,
        title:"3D全景管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"mySaveList",
                sbuMenu:true,
                subs:null,
                title:"我的保存列表",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-video-camera-solid",
        index:"PanoramicManagement",
        sbuMenu:true,
        title:"3D本地案例管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"panoramicMaterial",
                sbuMenu:false,
                subs:null,
                title:"全景素材",
                type:0,
            },
            {
                icon:"",
                index:"scenariosStyle",
                sbuMenu:true,
                subs:null,
                title:"本地案例风格",
                type:0,
            },
            {
                icon:"",
                index:"scenariosList",
                sbuMenu:true,
                subs:null,
                title:"本地案例列表",
                type:0,
            },
            {
                icon:"",
                index:"housingTypeManagement",
                sbuMenu:true,
                subs:null,
                title:"小区户型管理",
                type:0,
            },
            {
                icon:"",
                index:"designAssistant",
                sbuMenu:true,
                subs:null,
                title:"工具下载",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-video-play",
        index:"VideoTutorial",
        sbuMenu:true,
        title:"视频教程",
        type:0,
        subs:[
            {
                icon:"",
                index:"tutorialsList",
                sbuMenu:true,
                subs:null,
                title:"视频教程",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-document-copy",
        index:"VisitManagement",
        sbuMenu:false,
        title:"来访管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"visitorList",
                sbuMenu:true,
                subs:null,
                title:"来访列表",
                type:0,
            },
            {
                icon:"",
                index:"visitor",
                sbuMenu:true,
                subs:null,
                title:"来访录入",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-document-copy",
        index:"LighthouseManagement",
        sbuMenu:false,
        title:"灯塔计划",
        type:0,
        subs:[
            {
                icon:"",
                index:"lighthouseList",
                sbuMenu:true,
                subs:null,
                title:"意向客户",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseDaList",
                sbuMenu:true,
                subs:null,
                title:"经销商",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseSCList",
                sbuMenu:true,
                subs:null,
                title:"市场",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-document-copy",
        index:"LighthouseManagement",
        sbuMenu:false,
        title:"星光计划",
        type:0,
        subs:[
            {
                icon:"",
                index:"lighthouseList",
                sbuMenu:true,
                subs:null,
                title:"意向客户",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseDaList",
                sbuMenu:true,
                subs:null,
                title:"经销商",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseSCList",
                sbuMenu:true,
                subs:null,
                title:"市场",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-document-copy",
        index:"LighthouseManagement",
        sbuMenu:false,
        title:"星火计划",
        type:0,
        subs:[
            {
                icon:"",
                index:"lighthouseList",
                sbuMenu:true,
                subs:null,
                title:"意向客户",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseDaList",
                sbuMenu:true,
                subs:null,
                title:"经销商",
                type:0,
            },
            {
                icon:"",
                index:"lighthouseSCList",
                sbuMenu:true,
                subs:null,
                title:"市场",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-document-copy",
        index:"LighthouseManagement",
        sbuMenu:false,
        title:"客户分析",
        type:0,
        subs:[
            {
                icon:"",
                index:"lighthouseList",
                sbuMenu:true,
                subs:null,
                title:"客户分析",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-s-order",
        index:"OperatingHistory",
        sbuMenu:true,
        title:"操作历史",
        type:0,
        subs:[
            {
                icon:"",
                index:"operationList",
                sbuMenu:true,
                subs:null,
                title:"操作列表",
                type:0,
            },
        ],
    },
]

var menuJson = {
    storeMenu
}
export default menuJson