/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-03-30 14:37:36
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-27 15:12:14
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
import menu from './internal/menuJson'

import './assets/elementCss/dialogCss.css'
import axios from 'axios';
// 引入echarts
import * as echarts from 'echarts'
import Print from 'vue-print-nb'
Vue.use(Print)
Vue.prototype.$echarts = echarts

Vue.prototype.$axios = axios;

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$menu = menu
// 过滤器
import * as custom from './utils/util'
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
